<template>
	<div class="layout">
		<main>
			<div class="layout-aside layout-aside--column">
				<div class="layout-aside__menu">
					<ChatMenu />
				</div>

				<div class="layout-aside__main">
					<slot />
				</div>
			</div>
		</main>
	</div>
</template>

<script setup lang="ts">
	import { navigateTo } from "#app";

	watch(
		() => useUser(),
		(value) => {
			if (Object.keys(value).length === 0) {
				navigateTo("/logout");
			}
		},
	);
</script>
